//+++++++++++++++++++++++
//+++ HELPERS: COLORS +++
//+++++++++++++++++++++++

// Changes greyscale palette
$theme: grey;

// Basic colors
$red: #E4261C;
$pink: #E91E63;
$purple: #642240;
$light-purple: #793554;
$indigo: #3F51B5;
$blue: #1E2B50;
$light-blue: #03A9F4;
$dark-blue: #343E48;
$cyan: #00BCD4;
$teal: #009688;
$green: #4CAF50;
$light-green: #8BC34A;
$lime: #CDDC39;
$yellow: #FFEB3B;
$amber: #FFC107;
$orange: #EE7523;
$deep-orange: #FF5722;
$brown: #795548;
$cream: #F2E3B0;
$white: #FFFFFF;
$black: #000000;

// Standard greyscale: number = brightness (HSB)
$grey95: #F2F2F2;
$grey90: #E0DBD5;
$grey80: #CCCCCC;
$grey75: #D9D3CC;
$grey70: #B3B3B3;
$grey60: #999999;
$grey50: #808080;
$grey40: #666666;
$grey30: #4D4D4D;
$grey20: #333333;
$grey15: #262626;

// Colour scheming
@if $theme == blue {
    $grey95: #EDF1F2;
    $grey90: #DCE2E6;
    $grey80: #C0C7CC;
    $grey70: #A4ACB3;
    $grey60: #8A9299;
    $grey50: #707980;
    $grey40: #586066;
    $grey30: #40474D;
    $grey20: #2A2F33;
    $grey15: #1F2326;
} @else if $theme == brown {
    $grey95: #F2F2F0;
    $grey90: #E6E6E1;
    $grey80: #CCCCC6;
    $grey70: #B3B3AB;
    $grey60: #999991;
    $grey50: #808078;
    $grey40: #66665F;
    $grey30: #4D4D46;
    $grey20: #33332E;
    $grey15: #262622;
}

// Social colors
$dribbble: #EA4C89;
$facebook: #3B5999;
$flickr: #FF0084;
$googleplus: #DD4B39;
$instagram: #E4405F;
$linkedin: #0077B5;
$pinterest: #BD081C;
$snapchat: #FFFC00;
$soundcloud: #ff3300;
$tumblr: #34465D;
$twitter: #55ACEE;
$vimeo: #1AB7EA;
$whatsapp: #25D366;
$youtube: #CD201F;

// Social colors
.dribbble { color: $dribbble; };
.facebook { color: $facebook; };
.flickr { color: $flickr; };
.googleplus { color: $googleplus; };
.instagram { color: $instagram; };
.linkedin { color: $linkedin; };
.pinterest { color: $pinterest; };
.snapchat { color: $snapchat; };
.soundcloud { color: $soundcloud; };
.tumblr { color: $tumblr; };
.twitter { color: $twitter; };
.vimeo { color: $vimeo; };
.whatsapp { color: $whatsapp; };
.youtube { color: $youtube; };

//=======================================
// Helpers
//=======================================

// Gradients
@mixin linear-gradient($direction-old: left, $direction-new: to right, $colors ...) {
    background-image: -webkit-linear-gradient($direction-old, $colors);
    background-image: linear-gradient($direction-new, $colors);
}

@mixin radial-gradient($position, $colors ...) {
    background-image: -webkit-radial-gradient($position, $colors);
    background-image: radial-gradient($position, $colors);
}
