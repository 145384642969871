//++++++++++++++++
//+++ SETTINGS +++
//++++++++++++++++

// Set the color scheme
$primary: $blue;
$secondary: $orange;

// Creates a full palette from base colours
$primary-bright: lighten($primary, 20%);
$primary-light: lighten($primary, 10%);
$primary-dark: darken($primary, 10%);
$primary-shade: darken($primary, 20%);

$secondary-bright: lighten($secondary, 20%);
$secondary-light: lighten($secondary, 10%);
$secondary-dark: darken($secondary, 10%);
$secondary-shade: darken($secondary, 20%);

// Set common colors
$body-color: $white;
$header-color: $white;

// Typography
$font-header: 'calendas_plusbold', Georgia, Times, 'Times New Roman', serif;
$font-altheader: 'bebas_neuebold', 'Trebuchet MS', 'Lucida Grande', Tahoma, sans-serif;
$font-body: 'Roboto', 'Trebuchet MS', 'Lucida Grande', Tahoma, sans-serif;
$font-altbody: 'calendas_plusregular', Georgia, Times, 'Times New Roman', serif;
$font-italic: 'calendas_plusitalic', Georgia, Times, 'Times New Roman', serif;
$font-content: 'Roboto', 'Trebuchet MS', 'Lucida Grande', Tahoma, sans-serif;
$font-book: 'bebas_neuebook', 'Trebuchet MS', 'Lucida Grande', Tahoma, sans-serif;
$font-quote: 'bebas_neuebold', 'Trebuchet MS', 'Lucida Grande', Tahoma, sans-serif;
$font-meta: 'bebas_neuebold', 'Trebuchet MS', 'Lucida Grande', Tahoma, sans-serif;

$light: 300;
$normal: 400;
$bold: 700;

// Layout
$gap: 1em;
$corner: 0;
$line: 5px;
$page-width: 80em;
$shadow: 0 4px 8px rgba($black, 0.1);

// Sets width of fixed columns
$half-column: $page-width / 2;
$third-column: $page-width / 3;
$two-thirds-column: ($page-width / 3) * 2;
$fourth-column: $page-width / 4;
$three-fourths-column: ($page-width / 4) * 3;
$sixth-column: $page-width / 6;
$five-sixths-column: ($page-width / 6) * 5;

// Keep track of z-indexes to avoid z-index: 999999999;
$zindex: (
    popup: 50,
    header: 40,
    rnav: 30,
    footer: 20,
    overlay: 10,
    highlight: -10,
);

// Breakpoints
$breakpoints: (
    small: 560px,
    tablet: 700px,
    medium: 960px,
    large: 1280px,
    xlarge: 1440px,
);

// add CMS specific changes
$wordpress: true;
$drupal: false;
$drupal-version: 8;
